<template>
  <v-container>
    <v-row dense class="smaller mb-0 pb-0">
      <v-col class="pa-0">
        <v-text-field
          hide-details
          dense
          prepend-icon="mdi-magnify"
          aut-add-field-search
          clearable
          placeholder="Search"
          v-model="searchValue"
          @keyup="performSearch"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-0 pb-0" dense>
      <list-field
        label="Field"
        :list="filteredFieldTypes"
        :value="type"
        max_height="40"
        @select="type = $event"
      />
      <v-col cols="12" md="9" class="pt-0">
        <templates-list
          :search_value="searchValue"
          :templates="templates"
          max_height="40"
          @use_template="$emit('use_template', $event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { uniqBy } from "lodash";
import TemplatesList from "./TemplatesList.vue";
import { getMatches, fetchTemplates, getBackgroundColor } from "@/util.js";
import { getFieldDefinitions } from "@/components/fields/util.js";

const debug = require("debug")("atman.components.add_field");
export default {
  name: "NewField",
  components: {
    TemplatesList,
  },
  props: {
    filter: {
      type: String,
    },
    presets: {
      type: Object,
      default: () => ({}),
    },
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      renderKey: 1,
      fieldContext: "",
      fieldTypes: [],
      filteredFieldTypes: [],
      type: "text",
      templates: [],
      template: null,
      searchValue: "",
    };
  },
  computed: {
    backgroundColor() {
      return getBackgroundColor();
    },
  },

  watch: {
    type() {
      this.fetchTemplates();
    },
  },
  async mounted() {
    this.fieldTypes = this.filteredFieldTypes = getFieldDefinitions(
      this.filter
    );
    debug("fieldTypes", this.fieldTypes);
    this.fetchTemplates();
  },

  methods: {
    performSearch() {
      if (!this.searchValue) {
        this.filteredFieldTypes = this.fieldTypes;
        return;
      }
      let filteredFieldTypes = [];
      const searchKey = this.searchValue.toLowerCase();
      filteredFieldTypes = this.searchTemplate(searchKey) || [];

      const matchedFields = this.searchFields(searchKey) || [];
      /* This order is important and several form_editor tests will fail if it is changed */

      // If a single field matched the search
      if (matchedFields.length == 1) {
        // Select it
        const selectedField = matchedFields[0].id;
        this.type = selectedField;
      }

      filteredFieldTypes = uniqBy(
        [...filteredFieldTypes, ...matchedFields],
        "id"
      );

      this.filteredFieldTypes = filteredFieldTypes;
    },

    getFieldDefinition() {
      const component = this;
      return component.fieldTypes.find((field) => {
        return field.id == component.type;
      });
    },

    async fetchTemplates() {
      const component = this;
      component.template = null;
      component.templates = [];
      if (!component.type) {
        return;
      }
      const fieldDefinition = component.getFieldDefinition();
      const dynamicTemplates = await fetchTemplates(component.type);
      let templates = [...fieldDefinition.templates, ...dynamicTemplates];
      if (!this.isFeatureEnabled("editor.templates.show_tutorials")) {
        templates = templates.filter(
          (template) => !(template.keyword || []).includes("tutorial")
        );
      }
      component.templates = templates;
      if (!component.templates || !component.templates.length) {
        return;
      }

      component.template = component.templates[0].id;
    },

    searchFields(searchKey) {
      return getMatches(this.fieldTypes, searchKey, "filter");
    },
    searchTemplate(searchKey) {
      // Find any field match this filter
      return this.fieldTypes.filter(({ templates }) =>
        getMatches(templates, searchKey, "find")
      );
    },
  },
};
</script>
