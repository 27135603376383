<template>
  <v-container>
    <v-row dense class="smaller mb-0 pb-0">
      <v-col class="pa-0">
        <v-text-field
          hide-details
          dense
          prepend-icon="mdi-magnify"
          aut-add-field-search
          placeholder="Search"
          v-model="searchValue"
          @keyup="performSearch"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="fields.length" class="mt-0">
      <list-field
        label="Field"
        :list="filteredFields"
        :value="selectedField"
        max_height="40"
        @select="selectField"
      />
      <v-col cols="12" md="9" class="pt-0">
        <templates-list
          :templates="templates"
          max_height="40"
          @use_template="$emit('use_field', $event)"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <label class="red--text">No fields found</label>
    </v-row>
  </v-container>
</template>
<script>
import { defaultsDeep, omit } from "lodash";
import { clone, getMatches } from "@/util";
import TemplatesList from "./TemplatesList.vue";
const debug = require("debug")("atman.components.existing_field"); // eslint-disable-line

const createTemplates = (inputField) => {
  let field = clone(omit(inputField, ["_template"]));
  Object.assign(field, inputField?._template?._default);
  return [
    {
      label: "Input",
      filter_key: "input",
      description: "Field in Input Mode",
      value: { mode: "input" },
    },
    {
      label: "Display",
      filter_key: "display",
      description: "Field in Display Mode",
      value: { mode: "display" },
    },
  ]
    .filter((item) => {
      if (field.type == "actions" || field.type == "action") {
        return item.filter_key != "display";
      }
      return true;
    })
    .map((override) => {
      return defaultsDeep({}, override, { value: field });
    });
};
export default {
  name: "ExistingField",
  components: {
    TemplatesList,
  },
  props: {
    fields: {
      type: Array,
    },
  },
  watch: {
    fields() {
      this.selectField();
    },
  },
  data() {
    return {
      selectedField: null,
      searchValue: "",
      templates: [],
      filteredFields: this.fields || [],
    };
  },
  mounted() {
    this.selectField(this.fields[0]);
  },
  methods: {
    performSearch() {
      if (!this.searchValue) {
        this.filteredFields = this.fields || [];
        return;
      }
      const searchKey = this.searchValue.toLowerCase();
      const matches = getMatches(this.fields, searchKey, "filter");
      this.templates = [];
      this.filteredFields = matches;
      if (matches.length == 1) {
        this.selectField(matches[0]);
      }
    },
    selectField(field) {
      if (!field) {
        return;
      }
      if (typeof field == "string") {
        field = this.fields.find(({ name }) => name == field);
      }
      this.selectedField = field.name;
      this.templates = [];
      this.$nextTick(() => {
        this.templates = createTemplates(field);
      });
    },
  },
};
</script>
