<template>
  <v-list dense class="transparent">
    <list-title title="Templates" />
    <v-list-item-group
      class="overflow-y-auto behavior-templates-list"
      :style="`max-height: ${listHeight}vh`"
    >
      <v-list-item
        aut-template-list-item
        :behavior-template="temp.id"
        v-for="(temp, i) in templates"
        :key="i"
        class="pa-4"
        @click="setTemplate(temp)"
      >
        <v-col>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card class="pa-2 transparent elevation-1">
                {{ temp.label }}
                <div class="text-body-2 mb-4" v-html="temp.description"></div>
                <field-preview :definition="temp.value" />
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="primary">
                    <v-btn @click.stop="setTemplate(temp)">Use Template</v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
import ListTitle from "@/components/pageElements/ListTitle/ListTitle.vue";
import { getMatches } from "@/util";
const debug = require("debug")("templates_list"); // eslint-disable-line
export default {
  name: "TemplatesList",
  components: {
    ListTitle,
    FieldPreview: () => import("@/components/fields/Field/FieldPreview.vue"),
  },
  props: {
    templates: {
      type: Array,
    },
    search_value: {
      type: String,
    },
    max_height: {
      type: [Number, String],
      default: () => {
        return 52;
      },
    },
  },
  watch: {
    search_value() {
      this.goToTemplate();
    },
    templates() {
      this.goToTemplate();
    },
  },
  computed: {
    listHeight() {
      const height = parseInt(this.max_height, 10);
      return isNaN(height) ? 52 : height;
    },
  },
  mounted() {
    this.goToTemplate();
  },
  methods: {
    goToTemplate() {
      if (!this.search_value || !this.templates.length) {
        return;
      }
      debug(`Attempting to go to Template`);
      const searchKey = this.search_value.toLowerCase();
      const matchedTemplate = getMatches(this.templates, searchKey, "find");
      if (!matchedTemplate || !matchedTemplate.id) {
        debug(`No matched templates found. Aborting`);
        return;
      }
      const target = `[behavior-template="${matchedTemplate.id}"]`;
      if (!this.$el.querySelector(target)) {
        debug(`Matched template not visible. Aborting`);
        return;
      }
      this.$vuetify.goTo(target, { container: ".behavior-templates-list" });
      const allTemplates = document.querySelectorAll(
        ".behavior-templates-list [behavior-template]"
      );
      allTemplates.forEach((element) => {
        element.classList.remove("grey");
      });
      this.$nextTick(() => {
        const element = document.querySelector(
          `.behavior-templates-list ${target}`
        );
        if (element) {
          element.classList.add("grey");
        }
      });
    },
    setTemplate(template) {
      this.$emit("use_template", template);
    },
  },
};
</script>
