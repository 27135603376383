<template>
  <v-dialog
    v-model="dialog"
    aut-add-field-dialog
    width="1000"
    :scrollable="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row class="mt-2 pa-4">
        <v-spacer />
        <v-text-field
          v-model="fieldsAsString"
          placeholder="Add one or more fields separated by a comma"
          @keydown.enter.prevent="addFields"
        />
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          aut-add-fields
          class="mx-2"
          @click.stop="addFields"
        >
          Add
        </v-btn>
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          aut-show-add-field-dialog
          @click.stop="showDialog"
        >
          Advanced
        </v-btn>
      </v-row>
    </template>

    <v-card>
      <DialogTitle @close="dialog = false" title="Field Templates" />
      <v-card-text class="pb-1">
        <v-card outlined>
          <atman-tabs v-model="tabModel" @change="tabModel = $event">
            <v-tab aut-tab-new>New</v-tab>
            <v-tab aut-tab-existing :disabled="!existingFields.length"
              >Existing</v-tab
            >
          </atman-tabs>
          <atman-tabs-items v-model="tabModel">
            <v-tab-item aut-tab-item-new>
              <new-field
                class="pa-2"
                :filter="filter"
                :presets="presets"
                :definition="definition"
                @use_template="useTemplate"
              />
            </v-tab-item>
            <v-tab-item aut-tab-item-existing>
              <existing-field
                :context="context"
                :fields="existingFields"
                @use_field="selectField"
              />
            </v-tab-item>
          </atman-tabs-items>
        </v-card>
        <v-chip-group>
          <v-chip
            aut-selected-field
            v-for="(field, i) in selectedFields"
            :key="i"
            class="ma-1"
            color="primary"
            outlined
            close
            @click:close="selectedFields.splice(i, 1)"
          >
            {{ field.name }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <v-card-actions class="pb-1 pt-0">
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">Cancel</v-btn>
        <v-btn color="primary" @click="addAllFields" aut-add-selected-fields>
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="editDialog" width="500">
      <v-card aut-edit-template>
        <v-card-title> Edit Template Before Use</v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form" class="pa-2">
            <v-col>
              <v-text-field
                :disabled="!hasProperty('label')"
                aut-add-field-label
                dense
                small
                :required="hasProperty('label')"
                hint="Label to be displayed"
                persistent-hint
                :rules="applicableRules"
                @change="name = snakeCase(label)"
                v-model="label"
                label="Label"
              />
            </v-col>
            <v-col>
              <v-text-field
                :disabled="!hasProperty('name')"
                aut-add-field-name
                dense
                small
                hint="Internal name for this field"
                persistent-hint
                :required="hasProperty('name')"
                :rules="nameRules"
                v-model="name"
                label="Name"
              />
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="addField"
            :disabled="!valid"
            aut-action-list-field
            >Add</v-btn
          >
          <v-btn
            color="primary"
            @click="addAndClose"
            :disabled="!valid"
            aut-action-add-field
            >Add and Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import { rules, clone } from "@/util.js";
import { getFieldDefinitions } from "@/components/fields/util.js";
import { defaultsDeep, capitalize, snakeCase, omit } from "lodash";
import NewField from "./NewField.vue";
import ExistingField from "./ExistingField.vue";

const debug = require("debug")("atman.components.add_field");
export default {
  name: "AddFieldDialog",
  mixins: [dialogMixin],
  components: {
    NewField,
    ExistingField,
  },
  props: {
    filter: {
      type: String,
    },
    presets: {
      type: Object,
      default: () => ({}),
    },
    definition: {
      type: Object,
    },
    context: {
      type: String,
    },
  },
  data() {
    return {
      label: "",
      name: "",
      type: "text",
      fieldsAsString: "",
      valid: false,
      tabModel: null,
      template: null,
      existingFields: [],
      fieldTypes: [],
      dialog: false,
      editDialog: false,
      properties: [],
      applicableRules: [],
      selectedFields: [],
    };
  },
  computed: {
    nameRules() {
      if (!this.hasProperty("name")) {
        return [];
      }
      const names = [];
      let fields = this?.definition?.definition?.fields;
      fields.forEach(({ name }) => {
        names.push(name);
      });
      return [
        rules.mandatory(),
        (val) => !names.includes(val) || "This name has already been used",
      ];
    },
  },
  created() {
    this.snakeCase = snakeCase;
    this.fieldTypes = getFieldDefinitions(this.filter);
  },
  methods: {
    addFields() {
      const component = this;
      const fields = component.fieldsAsString
        .trim()
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item != "");
      if (!fields.length) {
        component.displayErrorMessage(`Please add one or more fields`);
        return;
      }
      const template = component.fieldTypes
        .find((field) => {
          return field.id == "text";
        })
        .templates.find(({ id }) => id == "default").value;
      const fieldsToAdd = fields.map((label) => {
        return defaultsDeep(
          {
            label: label
              .split(" ")
              .filter((item) => item != " ")
              .map((item) => capitalize(item))
              .join(" "),
            name: snakeCase(label),
          },
          clone(template),
          clone(component.presets)
        );
      });
      debug(`fieldsToAdd`, fieldsToAdd);
      fieldsToAdd.forEach((item) => {
        this.$emit("add", item);
      });
    },
    showDialog() {
      const component = this;
      this.tabModel = 0;
      this.selectedFields = [];

      debug("fieldTypes", this.fieldTypes);
      const fieldDefinition = component.getFieldDefinition(this.type);
      component.properties = fieldDefinition.properties.map((property) => {
        return typeof property === "string" ? property : property.key;
      });
      if (component.hasProperty("label")) {
        component.applicableRules = [rules.mandatory()];
      } else {
        component.applicableRules = [];
      }
      this.fetchSchema();
    },
    async fetchSchema() {
      const allFields = await this.$store.dispatch(
        `${this.context}/fetchSchema`
      );
      const existingFields = Object.keys(allFields).filter((fieldName) => {
        return typeof allFields[fieldName]._attr != "undefined";
      });
      if (existingFields.length > 0) {
        this.tabModel = 1;
      }
      this.existingFields = existingFields.map((fieldName) => {
        return allFields[fieldName]._attr;
      });
    },
    getFieldDefinition(type) {
      const component = this;
      return component.fieldTypes.find((field) => {
        return field.id == type;
      });
    },
    hasProperty(key) {
      debug("in hasProperty", key);
      return this.properties.includes(key);
    },
    useTemplate(chosenTemplate) {
      let template = chosenTemplate.value;
      this.template = template;
      const properties = this.getFieldDefinition(template.type).properties.map(
        (property) => {
          return typeof property === "string" ? property : property.key;
        }
      );
      const skipCustomisation = (chosenTemplate.keyword || []).includes(
        "skip_customisation"
      );

      if (properties.includes("name") && !skipCustomisation) {
        this.editDialog = true;
      } else {
        this.addField();
      }
    },
    addField() {
      this.editDialog = false;

      const component = this;
      const fieldName = component.name.startsWith("_")
        ? component.name
        : snakeCase(component.name);

      let skeleton = {};
      if (component.name) {
        skeleton = {
          name: fieldName,
          label: component.label,
        };
      }

      let chosenTemplateSkeleton = component.template;
      if (
        chosenTemplateSkeleton.type !== "actions" &&
        chosenTemplateSkeleton.type !== "action"
      ) {
        chosenTemplateSkeleton = omit(chosenTemplateSkeleton, "value");
      }
      const newField = Object.assign(
        {},
        component.presets,
        chosenTemplateSkeleton,
        skeleton
      );

      component.selectedFields.push(newField);

      if (component.name) {
        component.$refs.form.reset();
      }
    },
    selectField(chosenField) {
      const newField = chosenField.value;
      const fieldExists = this.selectedFields.find(
        (field) => field?.name == newField?.name
      );
      if (fieldExists) {
        this.displayErrorMessage("Field already selected");
      } else {
        this.selectedFields.push(newField);
      }
    },
    addAllFields() {
      this.selectedFields.forEach((field) => {
        this.$emit("add", field);
      });
      this.selectedFields = [];
    },
    addAndClose() {
      this.addField();
      this.addAllFields();
    },
  },
};
</script>
